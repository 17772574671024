import { getOffsetTop } from "./utils";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

document.querySelectorAll("a").forEach((link) => {
  var hashPos = link.href.indexOf("#");
  if (
    hashPos !== -1 &&
    !Object.keys(link.dataset).some(function (k) {
      return ~k.indexOf("bs");
    })
  ) {
    var id = link.href.substr(hashPos + 1);
    if (id) {
      var dest = document.getElementById(id);
      if (dest) {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          window.scrollTo({
            top: getOffsetTop(dest) + 64,
            behavior: "smooth",
          });
        });
      }
    }
  }
});
