require('./ico/android-chrome-512x512.png');
require('./ico/android-chrome-192x192.png');
require('./ico/apple-touch-icon.png');
require('./ico/browserconfig.xml');
require('./ico/favicon-16x16.png');
require('./ico/favicon-32x32.png');
require('./ico/favicon.ico');
require('./ico/mstile-150x150.png');
require('./ico/safari-pinned-tab.svg');
require('./ico/site.webmanifest');
